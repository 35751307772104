import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import PButton from '../components/PButton'
import {shortenUrl, uuidV4} from '../util/utils'
import {getAuthedUser} from '../util/auth'
import {getDatabase, ref, set} from 'firebase/database'
import {WaitingState} from '../model/GameState'
import {RoughNotation} from 'react-rough-notation'

export default function HomePage() {
  const [isCreating, setCreating] = useState(false)
  let navigate = useNavigate()

  return <div className={'flex flex-col items-center justify-center h-screen'}>
    <h1 className={'text-5xl tracking-widest font-bold'}>Draw and Guess</h1>
    <p className={'mt-2'}>
      By {' '}
      <RoughNotation type="underline" show animate={false} padding={0}>
        <a href="https://pegas.is" target="_blank" rel="noreferrer">Pegasis</a>
      </RoughNotation>
    </p>

    <PButton className={'mt-16'} onClick={async () => {
      setCreating(true)
      const roomID = await createRoom()
      navigate(`/room/${roomID}`)
    }}>
      {isCreating ? 'Creating.....' : 'Create Room'}
    </PButton>
  </div>
}

async function createRoom() {
  let roomID = uuidV4()
  let shortUrl = await shortenUrl(`https://draw.pegas.is/room/${roomID}`)
  let user = await getAuthedUser()
  let db = getDatabase()

  const players: Record<string, 1> = {}
  players[user.uid] = 1
  const gameState: WaitingState = {
    state: 'waiting',
    dictRef: null,
    roomInfo: {
      shortUrl,
      owner: user.uid,
      players,
      lastActionTime: Date.now(),
      kickedPlayers: {a: 1},
    },
  }

  await set(ref(db, `rooms/${roomID}`), gameState)

  return roomID
}

