import React, {ChangeEventHandler} from 'react'
import {RoughNotation} from 'react-rough-notation'

export default function PButton(props: {
  onClick?: () => void,
  disabled?: boolean,
  children: React.ReactNode,
  className?: string,
  compact?: boolean,
  highlight?:boolean,
}) {
  return <RoughNotation type={'highlight'} show={props.highlight} color={'rgba(255,225,0,0.3)'}>
    <button onClick={props.onClick}
            disabled={props.disabled}
            className={`hover:bg-black hover:bg-opacity-20 disabled:hover:bg-transparent active:opacity-50 disabled:opacity-50 tracking-wide bold ${props.compact ? 'px-[2px] text-sm' : 'px-[12px] py-[6px] text-base'} ${props.className}`}>
      <RoughNotation type={'box'} show animate={false} padding={props.compact ? [0, 2] : [6, 12]} strokeWidth={props.compact ? 1 : 2}>
        {props.children}
      </RoughNotation>
    </button>
  </RoughNotation>
}

export function PFileButton(props: {
  onChange?: ChangeEventHandler<HTMLInputElement>,
  disabled?: boolean,
  children: React.ReactNode,
  className?: string,
}) {
  return <label className={`hover:bg-black hover:bg-opacity-20 active:opacity-50 tracking-wide bold px-[12px] py-[6px] text-base ${props.className}`}>
    <RoughNotation type={'box'} show animate={false} padding={[6, 12]} strokeWidth={2}>
      {props.children}
    </RoughNotation>
    <input className={'hidden'} type={'file'} onChange={props.onChange}/>
  </label>
}
