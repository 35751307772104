import {UserName} from '../components/UserName'
import PButton, {PFileButton} from '../../components/PButton'
import React, {useEffect, useMemo, useState} from 'react'
import {ghostState, SelectingWordsState, WaitingState} from '../../model/GameState'
import {User} from 'firebase/auth'
import {get, getDatabase, ref, set} from 'firebase/database'
import {adjectives, animals, colors, uniqueNamesGenerator} from 'unique-names-generator'
import {DatabaseReference} from '@firebase/database'
import produce from 'immer'
import {useNavigate} from 'react-router-dom'
import QRCodeDialog from '../components/QRCodeDialog'
import {Dictionary, DictionaryRef} from '../../model/Dictionary'
// @ts-ignore
import sha1 from 'js-sha1'
import {shuffle} from 'lodash'
import PTextField from '../../components/PTextField'
import {isDev} from '../../util/utils'

export default function WaitingFragment({gameState, roomRef, user}: { gameState: WaitingState, roomRef: DatabaseReference, user: User }) {
  const userNameRef = useMemo(() => {
    const db = getDatabase()
    return ref(db, `users/${user.uid}/`)
  }, [user.uid])
  const [userName, setUserName] = useState('')
  const [isQROpen, setIsQROpen] = useState(false)
  let navigate = useNavigate()
  const [dictionary, setDictionary] = useState<Dictionary | null>(null)
  const [dictionaryRef, setDictionaryRef] = useState<DictionaryRef | null>(gameState.dictRef)
  const playerList = useMemo(() => {
    const list: string[] = []
    for (const player of Object.keys(gameState.roomInfo.players)) {
      if (player === gameState.roomInfo.owner) {
        list.unshift(player)
      } else {
        list.push(player)
      }
    }
    return list
  }, [gameState])
  const isRoomOwner = user.uid === gameState.roomInfo.owner

  useEffect(() => {
    get(userNameRef).then(snapshot => {
      if (snapshot.exists()) {
        setUserName(snapshot.val())
      } else {
        const userName = uniqueNamesGenerator({
          dictionaries: [colors, adjectives, animals],
          separator: ' ',
          style: 'capital',
        })
        setUserName(userName)
        set(userNameRef, userName)
      }
    })
  }, [userNameRef])

  return <>
    <QRCodeDialog roomInfo={gameState.roomInfo} open={isQROpen} onClose={() => setIsQROpen(false)}/>
    <div className={'fixed top-0 left-0 w-full flex flex-col items-center'}>
      <h1 className={'text-2xl font-bold mt-12'}>Joined Players:</h1>
      <ul className={'flex flex-col items-center mt-4'}>
        {playerList.map(player => <UserName key={player} uid={player} roomOwner={gameState.roomInfo.owner} kickConfig={isRoomOwner ? {roomRef, gameState} : undefined}/>)}
      </ul>
    </div>
    <div className={'fixed bottom-0 left-0 w-full flex flex-col items-center'}>
      <div className={'flex items-center'}>
        <span className={'mr-4 text-lg'}>Your Name:</span>
        <PTextField value={userName} onChange={text => {
          setUserName(text)
          set(userNameRef, text)
        }}/>
      </div>
      {isRoomOwner ?
        <div className={'mt-4 text-lg flex items-center'}>
          <span className={'mr-4'}>
            Dictionary:{' '}
            {dictionaryRef?.name ?? 'None'}
          </span>
          <PFileButton onChange={async e => {
            const file = e.target.files?.item(0)
            if (!file) return

            const content = await file.text()
            const hash: string = sha1(content)
            const words = content.split(/\r?\n/).map(word => word.trim())

            const dictRef = {
              name: file.name,
              hash,
            }
            setDictionaryRef(dictRef)
            setDictionary({
              count: words.length,
              words,
            })
            await set(roomRef, produce(gameState, draft => {
              draft.dictRef = dictRef
            }))
          }}>
            Select
          </PFileButton>
        </div> :
        <>
          <p className={'mt-4'}>
            Waiting for the host to start the game.....
          </p>
          <p>
            Dictionary:{' '}
            {gameState.dictRef ? `${gameState.dictRef.name}` : 'None'}
          </p>
        </>}

      <div className={'my-4 gap-x-4 grid grid-flow-col auto-cols-max'}>
        <PButton onClick={async () => {
          if (Object.keys(gameState.roomInfo.players).length > 1) {
            await set(roomRef, produce(gameState, draft => {
              delete draft.roomInfo.players[user.uid]
              if (isRoomOwner) {
                draft.roomInfo.owner = Object.keys(draft.roomInfo.players)[0]
              }
            }))
          } else {
            await set(roomRef, ghostState)
          }
          navigate('/')
        }}>
          Exit Room
        </PButton>
        <PButton onClick={() => setIsQROpen(true)}>
          Copy Room Link / QR Code
        </PButton>
        {isRoomOwner ? <PButton onClick={async () => {
          if (dictionaryRef === null) {
            alert('You need to select a dictionary first!')
            return
          }
          if (!isDev && Object.keys(gameState.roomInfo.players).length < 4) {
            alert('You need at least 4 people to start!')
            return
          }

          if (dictionary !== null) {
            const db = getDatabase()
            const dictDBRef = ref(db, `dictionaries/${dictionaryRef.hash}`)
            const dictSnapshot = await get(dictDBRef)
            if (!dictSnapshot.exists()) await set(dictDBRef, dictionary)
          }

          const newState: SelectingWordsState = {
            state: 'selecting',
            roomInfo: gameState.roomInfo,
            dictRef: dictionaryRef,
            selectedWords: {a: ''},
            pendingWords: {a: ['']},
            playerOrder: shuffle(Object.keys(gameState.roomInfo.players)),
          }
          await set(roomRef, newState)
        }}>
          Start Game
        </PButton> : null}
      </div>
    </div>
  </>
}