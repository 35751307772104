import {Drawing, Guessing, PresentationState, WaitingState} from '../../model/GameState'
import {DatabaseReference} from '@firebase/database'
import {User} from 'firebase/auth'
import {UserName} from '../components/UserName'
import {circularGet, dbWaitUntil, objDiff, produceUpdate, sleep} from '../../util/utils'
import Canvas from '../components/Canvas'
import PDialog from '../../components/PDialog'
import PButton from '../../components/PButton'
import React, {useMemo, useState} from 'react'
// @ts-ignore
import CanvasDraw from '@pegasis/react-canvas-draw'
import {useEffectOnce} from 'react-use'
import {get, set, update} from 'firebase/database'
import produce from 'immer'

function Round0({gameState}: { gameState: PresentationState }) {
  return <div className={'fixed left-0 right-0 top-0 bottom-0 flex flex-col items-center justify-center'}>
    <UserName className={'text-xl'} uid={gameState.playerOrder[gameState.currPlayerI]}/>
    <p className={'text-4xl mt-4'}>{(gameState.history[0][circularGet(gameState.playerOrder, gameState.currPlayerI - 1)] as Guessing).word}</p>
  </div>
}

function DrawingSection({gameState}: { gameState: PresentationState }) {
  const drawingRound = gameState.currRound % 2 === 0 ? gameState.currRound - 1 : gameState.currRound
  const drawerI = gameState.currPlayerI + drawingRound - 1
  const drawer = circularGet(gameState.playerOrder, drawerI)
  const prevGuesserI = drawerI - 1
  const prevGuesser = circularGet(gameState.playerOrder, prevGuesserI)

  return <>
    <div className={'fixed left-0 right-0 top-0 flex flex-col items-center z-10'}>
      <p className={'text-2xl font-bold mt-4'}>{(gameState.history[drawingRound - 1][prevGuesser] as Guessing).word}</p>
      <div className={'flex items-center text-lg'}><UserName uid={drawer} className={'mr-2'}/> drew:</div>
    </div>
    <Canvas disabled animation saveData={(gameState.history[drawingRound][drawer] as Drawing).data}/>
  </>
}

function GuessingSection({gameState}: { gameState: PresentationState }) {
  const drawingRound = gameState.currRound % 2 === 0 ? gameState.currRound - 1 : gameState.currRound
  const drawerI = gameState.currPlayerI + drawingRound - 1
  const guesserI = drawerI + 1
  const guesser = circularGet(gameState.playerOrder, guesserI)

  return <div className={'fixed left-0 right-0 bottom-0 flex flex-col items-center z-10'}>
    <div className={'flex items-center text-xl mb-10'}>
      <UserName uid={guesser} className={'mr-2'}/>
      guessed: {(gameState.history[drawingRound + 1][guesser] as Guessing).word}
    </div>
  </div>
}

function VotingDialog({gameState, roomRef, user}: { gameState: PresentationState, roomRef: DatabaseReference, user: User }) {
  const vote = gameState.votes[user.uid]
  return <PDialog open={gameState.subState === 'voting'} className={'px-10 py-4'}>
    <span className={'text-2xl'}>{(gameState.history[0][circularGet(gameState.playerOrder, gameState.currPlayerI - 1)] as Guessing).word}</span>
    <span className={'text-2xl'}>↓</span>
    <span className={'text-2xl'}>
      {(gameState.history[gameState.history.length - 1][circularGet(gameState.playerOrder, gameState.currPlayerI - gameState.history.length - (gameState.playerOrder.length % 2 === 0 ? 0 : 2))] as Guessing).word}
    </span>
    <div className={'grid grid-cols-2 place-items-center gap-x-8 mt-6 mb-4'}>
      <PButton highlight={vote === 1} onClick={() => produceUpdate(roomRef, gameState, draft => {
        draft.votes[user.uid] = 1
      })}>
        Match
      </PButton>
      <PButton highlight={vote === -1} onClick={() => produceUpdate(roomRef, gameState, draft => {
        draft.votes[user.uid] = -1
      })}>
        Not Match
      </PButton>
      <span className={'text-2xl'}>
          {Object.values(gameState.votes).filter(v => v === 1).length}
        </span>
      <span className={'text-2xl'}>
          {Object.values(gameState.votes).filter(v => v === -1).length}
        </span>
    </div>
  </PDialog>
}

function SelectingFavouriteDialogs({gameState, roomRef, user}: { gameState: PresentationState, roomRef: DatabaseReference, user: User }) {
  const drawings = useMemo(() => {
    const drawings: string[] = []
    for (let drawingRound = 1; drawingRound < gameState.history.length; drawingRound += 2) {
      const drawerI = gameState.currPlayerI + drawingRound - 1
      const drawer = circularGet(gameState.playerOrder, drawerI)
      drawings.push((gameState.history[drawingRound][drawer] as Drawing).data)
    }
    return drawings
  }, [gameState.currPlayerI, gameState.history, gameState.playerOrder])
  const [selectedI, setSelectedI] = useState(0)

  return <>
    <PDialog className={'px-6 py-4'} open={gameState.subState === 'selecting-favourite' && gameState.currPlayerI === gameState.playerOrder.indexOf(user.uid)}>
      <h3 className={'text-xl'}>Choose your favourite drawing!</h3>
      <div className={'flex items-center mt-4'}>
        <PButton disabled={selectedI === drawings.length - 1} onClick={() => setSelectedI(selectedI + 1)}>
          {'<-'}
        </PButton>
        <CanvasDraw
          className={'shadow-md mx-6'}
          canvasWidth={16 * 35}
          canvasHeight={9 * 35}
          hideInterface
          immediateLoading
          saveData={drawings[selectedI]}
          disabled/>
        <PButton disabled={selectedI === 0} onClick={() => setSelectedI(selectedI - 1)}>
          {'->'}
        </PButton>
      </div>
      <PButton className={'mt-8 mb-4'} onClick={() => {
        produceUpdate(roomRef, gameState, draft => {
          draft.favouriteDrawingData = drawings[selectedI]
        })
      }}>
        Choose
      </PButton>
    </PDialog>
    <PDialog className={'px-6 py-4'} open={gameState.subState === 'selecting-favourite' && gameState.currPlayerI !== gameState.playerOrder.indexOf(user.uid)}>
      <h3 className={'flex text-xl'}>
        <UserName className={'mr-2'} uid={gameState.playerOrder[gameState.currPlayerI]}/>
        is selecting his/her favorite drawing!
      </h3>
    </PDialog>
  </>
}

function FavouriteDialog({gameState}: { gameState: PresentationState }) {
  return <PDialog className={'px-6 pt-4 pb-6'} open={gameState.subState === 'favourite'}>
    <h3 className={'flex text-xl'}>
      <UserName uid={gameState.playerOrder[gameState.currPlayerI]}/>'s{' '}
      favorite drawing:
    </h3>
    <CanvasDraw
      className={'shadow-md mt-6'}
      canvasWidth={16 * 35}
      canvasHeight={9 * 35}
      hideInterface
      immediateLoading
      saveData={gameState.favouriteDrawingData}
      disabled/>
  </PDialog>
}

export default function PresentationFragment({gameState, roomRef, user}: { gameState: PresentationState, roomRef: DatabaseReference, user: User }) {
  useEffectOnce(() => {
    if (user.uid !== gameState.roomInfo.owner) return

    async function nextState(state: PresentationState): Promise<PresentationState | WaitingState> {
      if (state.currRound < gameState.history.length - 1) {
        if (state.currRound === 0) {
          await sleep(2000)
        } else if (state.currRound % 2 === 0) {
          // guess
          await sleep(3000)
        } else {
          // draw
          await sleep(2000)
        }

        return produce(state, draft => {
          draft.currRound++
        })
      } else {
        if (state.subState === 'normal') {
          await sleep(3000)
          return produce(state, draft => {
            draft.subState = 'voting'
            const votes: Record<string, number> = {}
            draft.playerOrder.forEach(player => votes[player] = 0)
            draft.votes = votes
          })
        } else if (state.subState === 'voting') {
          await dbWaitUntil<PresentationState>(roomRef, value => {
            return Object.values(value.votes).filter(v => v !== 0).length === value.playerOrder.length
          })
          return produce(state, draft => {
            draft.subState = 'selecting-favourite'
            draft.favouriteDrawingData = ''
          })
        } else if (state.subState === 'selecting-favourite') {
          await dbWaitUntil<PresentationState>(roomRef, value => {
            return value.favouriteDrawingData !== ''
          })
          return produce(state, draft => {
            draft.subState = 'favourite'
          })
        } else {
          await sleep(3000)
          if (state.currPlayerI < gameState.playerOrder.length - 1) {
            return produce(state, draft => {
              draft.subState = 'normal'
              draft.currRound = 0
              draft.currPlayerI++
            })
          } else {
            return {
              state: 'waiting',
              roomInfo: gameState.roomInfo,
              dictRef: gameState.dictRef,
            }
          }
        }
      }
    }

    (async () => {
      // return
      let oldState = gameState
      while (true) {
        const newState = await nextState(oldState)
        if (newState.state === 'presentation') {
          await update(roomRef, objDiff(newState, oldState))
          oldState = (await get(roomRef)).val()
        } else {
          await set(roomRef, newState)
          break
        }
      }
    })()
  })

  if (gameState.currRound === 0) {
    return <Round0 gameState={gameState}/>
  }

  return <>
    <DrawingSection gameState={gameState}/>
    {gameState.currRound % 2 === 0 ? <GuessingSection gameState={gameState}/> : null}
    <VotingDialog gameState={gameState} roomRef={roomRef} user={user}/>
    <SelectingFavouriteDialogs gameState={gameState} roomRef={roomRef} user={user}/>
    <FavouriteDialog gameState={gameState}/>
  </>
}