import {getAuth, signInAnonymously, User} from 'firebase/auth'
import {errorAlert} from './utils'
import {useState} from 'react'

export async function getAuthedUser(): Promise<User> {
  const auth = getAuth()
  if (auth.currentUser) {
    return auth.currentUser
  }
  try {
    const credential = await signInAnonymously(auth)
    if (credential.user) {
      return credential.user
    } else {
      throw new Error('user is null')
    }
  } catch (e) {
    errorAlert(e)
    throw e
  }
}

export function useAuthedUser(): User | null {
  const [user, setUser] = useState<User | null>(null)
  const [isSigningIn, setIsSigningIn] = useState(false)

  if (user !== null) return user

  const auth = getAuth()
  if (auth.currentUser) {
    setUser(auth.currentUser)
  } else if (!isSigningIn) {
    setIsSigningIn(true);
    (async () => {
      try {
        const credential = await signInAnonymously(auth)
        if (credential.user) {
          return credential.user
        } else {
          throw new Error('user is null')
        }
      } catch (e) {
        errorAlert(e)
        throw e
      } finally {
        setIsSigningIn(false)
      }
    })()
  }

  return user
}