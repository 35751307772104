import PButton from '../../components/PButton'
import React, {useState} from 'react'
import PDialog from '../../components/PDialog'
import {UserName} from './UserName'
import {DrawingState, GuessingState, SelectingWordsState} from '../../model/GameState'
import {User} from 'firebase/auth'
import {DatabaseReference} from '@firebase/database'

export default function PlayerListButton(props: { className?: string, roomRef: DatabaseReference, gameState: SelectingWordsState | DrawingState | GuessingState, user: User }) {
  const [open, setOpen] = useState(false)
  let isPlayerFinished: (player: string) => boolean

  if (props.gameState.state === 'selecting') {
    isPlayerFinished = player => !!(props.gameState as SelectingWordsState).selectedWords[player]
  } else if (props.gameState.state === 'drawing') {
    isPlayerFinished = player => !!(props.gameState as DrawingState).drawings[player]
  } else {
    isPlayerFinished = player => !!(props.gameState as GuessingState).guessings[player]
  }

  return <>
    <PButton className={props.className} onClick={() => setOpen(true)}>
      Player List
    </PButton>
    <PDialog open={open} className={'px-6 py-4'}>
      <h3 className={'text-xl'}>Joined Players: </h3>
      <ul className={'flex flex-col items-center mt-4'}>
        {props.gameState.playerOrder.map(player => <div key={player} className={'flex items-center'}>
          <span className={'w-4 text-green-600'}>{isPlayerFinished(player) ? '✓' : ''}</span>
          <UserName uid={player} highlightHost={false} roomOwner={props.gameState.roomInfo.owner}
                    kickConfig={props.gameState.roomInfo.owner === props.user.uid ? {roomRef: props.roomRef, gameState: props.gameState} : undefined}/>
        </div>)}
      </ul>
      <PButton onClick={() => setOpen(false)} className={'mt-8 mb-2'}>
        Close
      </PButton>
    </PDialog>
  </>
}