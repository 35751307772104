import {DrawingState, Guessing, SelectingWordsState, UserID} from '../../model/GameState'
import {DatabaseReference} from '@firebase/database'
import {User} from 'firebase/auth'
import usePromise from 'react-use-promise'
import {get, getDatabase, ref, set} from 'firebase/database'
import {RoughNotation} from 'react-rough-notation'
import {circularGet, produceUpdate} from '../../util/utils'
import React, {useCallback} from 'react'
import FinishCounter from '../components/FinishCounter'
import PlayerListButton from '../components/PlayerListButton'

export default function SelectingWordsFragment({gameState, roomRef, user}: { gameState: SelectingWordsState, roomRef: DatabaseReference, user: User }) {
  const isRoomOwner = user.uid === gameState.roomInfo.owner
  const [words] = usePromise(async () => {
    if (gameState.pendingWords[user.uid]) {
      return gameState.pendingWords[user.uid]
    }

    const db = getDatabase()
    const dictCount = (await get(ref(db, `dictionaries/${gameState.dictRef.hash}/count`))).val() as number

    const words: string[] = []
    for (let i = 0; i < 3; i++) {
      words.push((await get(ref(db, `dictionaries/${gameState.dictRef.hash}/words/${Math.floor(Math.random() * dictCount)}`))).val())
    }

    await produceUpdate(roomRef, gameState, draft => {
      draft.pendingWords[user.uid] = words
    })

    return words
  }, [])

  const onFinish = useCallback(() => {
    if (!isRoomOwner) return
    const guessings: Record<UserID, Guessing> = {}
    for (let i = 0; i < gameState.playerOrder.length; i++) {
      const player = gameState.playerOrder[i]
      const prevPlayer = circularGet(gameState.playerOrder, i - 1)
      guessings[prevPlayer] = {
        time: 0,
        word: gameState.selectedWords[player],
      }
    }

    const newState: DrawingState = {
      state: 'drawing',
      roomInfo: gameState.roomInfo,
      dictRef: gameState.dictRef,
      history: [guessings],
      starts: Date.now(),
      nextStarts: Date.now() + 100 * 1000,
      playerOrder: gameState.playerOrder,
      drawings: {a: {time: 0, data: ''}},
    }
    set(roomRef, newState)
  }, [gameState.dictRef, gameState.playerOrder, gameState.roomInfo, gameState.selectedWords, isRoomOwner, roomRef])

  return <>
    <h1 className={'fixed left-0 right-0 top-0 text-center text-2xl font-bold mt-6'}>Select a Word:</h1>
    <ul className={'fixed left-0 right-0 top-0 bottom-0 grid grid-flow-col auto-cols-max text-4xl gap-x-32 mt-8 place-content-center'}>
      {words?.map((word, i) =>
        <button key={i} className={'hover:bg-black hover:bg-opacity-20 active:opacity-50 px-[12px] py-[6px]'} onClick={() => {
          produceUpdate(roomRef, gameState, draft => {
            draft.selectedWords[user.uid] = word
          })
        }}>
          <RoughNotation type={'highlight'} show={word === gameState.selectedWords[user.uid]} color={'rgba(255,225,0,0.3)'}>
            <RoughNotation type={'box'} show animate={false} padding={[6, 12]} strokeWidth={2}>
              {word}
            </RoughNotation>
          </RoughNotation>
        </button>)}
    </ul>
    <div className={'fixed top-0 left-0 pt-8 pl-8 flex items-center'}>
      <div className={'w-36'}>
        <FinishCounter word={'selected'} curr={Object.keys(gameState.selectedWords).length - 1} total={Object.keys(gameState.roomInfo.players).length} onFinish={onFinish}/>
      </div>
      <PlayerListButton className={'ml-8'} gameState={gameState} roomRef={roomRef} user={user}/>
    </div>
  </>
}

