import QRCode from 'react-qr-code'
import PButton from '../../components/PButton'
import PDialog from '../../components/PDialog'
import React, {useEffect} from 'react'
import {RoomInfo} from '../../model/GameState'
import {copyTextToClipboard} from '../../util/utils'
import {RoughNotation} from 'react-rough-notation'

export default function QRCodeDialog(props: {
  roomInfo: RoomInfo,
  open: boolean,
  onClose: () => void,
}) {
  const joinUrl = props.roomInfo.shortUrl ?? window.location.href
  useEffect(() => {
    if (props.open) copyTextToClipboard(joinUrl)
  }, [joinUrl, props.open])

  return <PDialog open={props.open} className={'px-6 py-4'}>
    <h3 className={'text-xl'}>
      Join the room at:
    </h3>
    <div className={'font-mono'}>
      <RoughNotation type={'highlight'} show animate={false} color={'rgba(255,225,0,0.3)'} padding={0}>
        <span className={'px-2'}>
          {joinUrl}
        </span>
      </RoughNotation>
    </div>
    <h3 className={'mt-4 text-xl'}>
      Or scan the QR code:
    </h3>
    <QRCode className={'mt-1'} value={joinUrl} bgColor={'rgba(255,255,255,0)'}/>
    <PButton onClick={props.onClose} className={'mt-8 mb-2'}>
      Close
    </PButton>
  </PDialog>
}