import React, {useEffect, useMemo, useState} from 'react'
import {getDatabase, onValue, ref, set} from 'firebase/database'
import {RoughNotation} from 'react-rough-notation'
import {DatabaseReference} from '@firebase/database'
import {GameState} from '../../model/GameState'
import PButton from '../../components/PButton'
import produce from 'immer'

const cache: Record<string, string> = {}

export function UserName(props: { uid: string, roomOwner?: string, className?: string, highlightHost?: boolean, kickConfig?: { roomRef: DatabaseReference, gameState: GameState } }) {
  const [displayName, setDisplayName] = useState<string>(cache[props.uid] ?? '---')
  const userNameRef = useMemo(() => {
    const db = getDatabase()
    return ref(db, `users/${props.uid}/`)
  }, [props.uid])

  useEffect(() => {
    return onValue(userNameRef, snapshot => {
      if (snapshot.exists()) {
        const name = snapshot.val()
        setDisplayName(name)
        cache[props.uid] = name
      } else {
        setDisplayName(props.uid)
        cache[props.uid] = props.uid
      }
    })
  }, [props.uid, userNameRef])

  if (props.roomOwner === props.uid) {
    const text = `${displayName === '' ? '(Empty Name)' : displayName} (host)`
    if (props.highlightHost !== false) {
      return <RoughNotation type={'highlight'} show animate={false} color={'rgba(255,225,0,0.3)'} padding={0}>
        <span className={`px-2 ${props.className}`}>
        {text}
      </span>
      </RoughNotation>
    } else {
      return <span className={props.className}>
        {text}
      </span>
    }
  } else {
    return <span className={'flex items-center'}>
      <span className={props.className}>
      {`${displayName === '' ? '(Empty Name)' : displayName}`}
      </span>
      {props.kickConfig ? <PButton key={displayName} compact className={'ml-2'} onClick={() => {
        set(props.kickConfig!.roomRef, produce(props.kickConfig!.gameState, draft => {
          if (draft.state === 'ghost') return
          delete draft.roomInfo.players[props.uid]
          draft.roomInfo.kickedPlayers[props.uid] = 1
        }))
      }}>Kick</PButton> : null}
    </span>
  }

}