import React, {useEffect} from 'react'

export default function FinishCounter(props: { curr: number, total: number, onFinish?: () => void, word: string }) {
  const finished = props.curr >= props.total
  useEffect(() => {
    if (finished && props.onFinish) props.onFinish()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finished, props.onFinish])

  return <p>{props.curr}/{props.total} {props.word}</p>
}