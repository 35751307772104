import React, {useEffect, useState} from 'react'
import {useInterval} from 'react-use'

export default function CountDown(props: { until: number, onZero?: () => void, className?: string }) {
  const [time, setTime] = useState(Date.now())
  useEffect(() => {
    if (time > props.until && props.onZero) {
      props.onZero()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time > props.until, props.onZero])
  useInterval(() => {
    setTime(Date.now)
  }, 100)

  return <span>
    {Math.max(Math.floor((props.until - time) / 1000), 0)}
  </span>
}