import {DictionaryRef} from './Dictionary'

export type UserID = string

export type RoomInfo = {
  shortUrl: string,
  owner: UserID,
  players: Record<UserID, 1>,
  kickedPlayers: Record<UserID, 1>,
  lastActionTime: number,
}

export type WaitingState = {
  state: 'waiting',
  roomInfo: RoomInfo,
  dictRef: DictionaryRef | null,
}

export type GhostState = {
  state: 'ghost'
}

export const ghostState: GhostState = {state: 'ghost'}

export type Guessing = { time: number, word: string }

export type Drawing = { time: number, data: string }

type CommonInGameState = {
  roomInfo: RoomInfo,
  dictRef: DictionaryRef,
  playerOrder: string[],
}

export type SelectingWordsState = {
  state: 'selecting',
  pendingWords: Record<UserID, string[]>,
  selectedWords: Record<UserID, string>,
} & CommonInGameState

// odd index: guessing  even index: drawing
export type History = Array<Record<UserID, Drawing> | Record<UserID, Guessing>>

export type DrawingState = {
  state: 'drawing',
  history: History,
  drawings: Record<UserID, Drawing>,
  starts: number,
  nextStarts: number,
} & CommonInGameState

export type GuessingState = {
  state: 'guessing',
  history: History,
  guessings: Record<UserID, Guessing>,
  starts: number,
  nextStarts: number,
} & CommonInGameState

export type PresentationState = {
  state: 'presentation',
  history: History,
  currPlayerI: number,
  currRound: number,
  subState: 'normal' | 'voting' | 'selecting-favourite' | 'favourite',
  favouriteDrawingData: string,
  votes: Record<UserID, number>,
  scores: Record<UserID, number>,
} & CommonInGameState

export type GameState = WaitingState | GhostState | SelectingWordsState | DrawingState | GuessingState | PresentationState