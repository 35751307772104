import {DrawingState, Guessing, GuessingState} from '../../model/GameState'
import {DatabaseReference} from '@firebase/database'
import {User} from 'firebase/auth'
import React, {useCallback, useEffect, useRef, useState} from 'react'
// @ts-ignore
import CanvasDraw from '@pegasis/react-canvas-draw'
import {RoughNotation} from 'react-rough-notation'
import PButton from '../../components/PButton'
import CountDown from '../components/CountDown'
import {produceUpdate} from '../../util/utils'
import PlayerDirectionIndicator from '../components/PlayerDirectionIndicator'
import {usePrevAndNextPlayer} from '../utils'
import FinishCounter from '../components/FinishCounter'
import {set} from 'firebase/database'
import Canvas from '../components/Canvas'
import PlayerListButton from '../components/PlayerListButton'
import useKeyboardJs from 'react-use/lib/useKeyboardJs'

function ColorDot(props: { color: string, size: string, selected: boolean, disabled: boolean, onClick: () => void }) {
  return <RoughNotation type={'circle'} show={props.selected} animationDuration={400}>
    <div onClick={() => {
      if (!props.disabled) props.onClick()
    }} style={{backgroundColor: props.color, width: props.size, height: props.size}} className={`rounded-full ${props.disabled ? '' : 'cursor-pointer'}`}/>
  </RoughNotation>
}

const colors = [
  '#000000',
  '#FF0000',
  '#00e100',
  '#0000FF',
  '#ffb700',
  '#14e8e8',
  '#FF00FF',
  '#cccccc',
]

const sizes = [
  {brushW: 5, displaySize: '16px'},
  {brushW: 10, displaySize: '22px'},
  {brushW: 25, displaySize: '32px'},
]

export default function DrawingFragment({gameState, roomRef, user}: { gameState: DrawingState, roomRef: DatabaseReference, user: User }) {
  const isRoomOwner = user.uid === gameState.roomInfo.owner
  const [prevPlayer, nextPlayer] = usePrevAndNextPlayer(gameState.playerOrder, user)
  const [selectedColor, setSelectedColor] = useState(colors[0])
  const [selectedSize, setSelectedSize] = useState(sizes[1])
  const canvasRef = useRef<CanvasDraw>()
  const submitted = !!gameState.drawings[user.uid]
  const onFinish = useCallback(() => {
    if (!isRoomOwner) return

    const newState: GuessingState = {
      state: 'guessing',
      roomInfo: gameState.roomInfo,
      dictRef: gameState.dictRef,
      starts: Date.now(),
      nextStarts: Date.now() + 100 * 1000,
      playerOrder: gameState.playerOrder,
      history: [...gameState.history, gameState.drawings],
      guessings: {a: {time: 0, word: ''}},
    }
    set(roomRef, newState)
  }, [gameState.dictRef, gameState.drawings, gameState.history, gameState.playerOrder, gameState.roomInfo, isRoomOwner, roomRef])
  const [ctrlZPressed] = useKeyboardJs('ctrl + z')
  useEffect(() => {
    if (ctrlZPressed) canvasRef.current!.undo()
  }, [ctrlZPressed])

  return <>
    <Canvas ref={canvasRef} strokeColor={selectedColor} strokeWidth={selectedSize.brushW} submitted={submitted} saveData={gameState.drawings[user.uid]?.data}/>
    <PlayerDirectionIndicator prev={prevPlayer} next={nextPlayer}/>
    <h1 className={'fixed top-0 left-0 right-0 text-center text-2xl font-bold xl:mt-6 mt-24'}>
      Draw "{(gameState.history[gameState.history.length - 1][prevPlayer] as Guessing).word}":
    </h1>
    <div className={'fixed top-0 right-0 flex items-center pt-8 pr-8'}>
      <PButton className={'mr-6'} disabled={submitted} onClick={() => canvasRef.current!.undo()}>
        Undo
      </PButton>
      <div className={'flex items-center mr-4'}>
        {sizes.map(size => <div key={size.brushW} className={'mr-4'}>
          <ColorDot disabled={submitted} color={selectedColor} size={size.displaySize} selected={size.brushW === selectedSize.brushW} onClick={() => setSelectedSize(size)}/>
        </div>)}
      </div>
      <div className={'grid grid-cols-4 gap-2 mr-8'}>
        {colors.map(color => <ColorDot disabled={submitted} key={color} color={color} size={sizes[1].displaySize} selected={color === selectedColor}
                                       onClick={() => setSelectedColor(color)}/>)}
      </div>
      <PButton disabled={submitted} onClick={() => {
        produceUpdate(roomRef, gameState, draft => {
          draft.drawings[user.uid] = {
            time: Date.now() - draft.starts,
            data: canvasRef.current!.getSaveData(),
          }
        })
      }}>
        Submit
      </PButton>
    </div>
    <div className={'fixed top-0 left-0 pt-8 pl-8 flex items-center'}>
      <div className={'w-36'}>
        <p>
          Count down:{' '}
          <CountDown until={gameState.nextStarts}/>
        </p>
        <FinishCounter word={'submitted'} curr={Object.keys(gameState.drawings).length - 1} total={Object.keys(gameState.roomInfo.players).length} onFinish={onFinish}/>
      </div>
      <PlayerListButton className={'ml-8'} gameState={gameState} roomRef={roomRef} user={user}/>
    </div>
  </>
}