import {Drawing, DrawingState, GuessingState, PresentationState} from '../../model/GameState'
import {DatabaseReference} from '@firebase/database'
import {User} from 'firebase/auth'
import {usePrevAndNextPlayer} from '../utils'
import PlayerDirectionIndicator from '../components/PlayerDirectionIndicator'
import React, {useCallback, useState} from 'react'
import Canvas from '../components/Canvas'
import PTextField from '../../components/PTextField'
import PButton from '../../components/PButton'
import {produceUpdate} from '../../util/utils'
import CountDown from '../components/CountDown'
import FinishCounter from '../components/FinishCounter'
import {set} from 'firebase/database'
import PlayerListButton from '../components/PlayerListButton'

export default function GuessingFragment({gameState, roomRef, user}: { gameState: GuessingState, roomRef: DatabaseReference, user: User }) {
  const isRoomOwner = user.uid === gameState.roomInfo.owner
  const [prevPlayer, nextPlayer] = usePrevAndNextPlayer(gameState.playerOrder, user)
  const [guess, setGuess] = useState(gameState.guessings[user.uid]?.word ?? '')
  const submitted = !!gameState.guessings[user.uid]

  const onFinish = useCallback(() => {
    if (!isRoomOwner) return
    if (gameState.history.length + 2 > gameState.playerOrder.length) {
      const scores: Record<string, number> = {}
      for (const player of gameState.playerOrder) {
        scores[player] = 0
      }
      const newState: PresentationState = {
        state: 'presentation',
        roomInfo: gameState.roomInfo,
        dictRef: gameState.dictRef,
        playerOrder: gameState.playerOrder,
        history: [...gameState.history, gameState.guessings],
        subState: 'normal',
        currPlayerI: 0,
        currRound: 0,
        votes: {a: 0},
        scores,
        favouriteDrawingData: '',
      }
      set(roomRef, newState)
    } else {
      const newState: DrawingState = {
        state: 'drawing',
        roomInfo: gameState.roomInfo,
        dictRef: gameState.dictRef,
        starts: Date.now(),
        nextStarts: Date.now() + 100 * 1000,
        playerOrder: gameState.playerOrder,
        history: [...gameState.history, gameState.guessings],
        drawings: {a: {time: 0, data: ''}},
      }
      set(roomRef, newState)
    }
  }, [gameState.dictRef, gameState.guessings, gameState.history, gameState.playerOrder, gameState.roomInfo, isRoomOwner, roomRef])

  const onSubmit = useCallback(() => {
    produceUpdate(roomRef, gameState, draft => {
      draft.guessings[user.uid] = {
        time: Date.now() - draft.starts,
        word: guess,
      }
    })
  }, [gameState, guess, roomRef, user.uid])

  return <>
    <Canvas disabled submitted={submitted} saveData={(gameState.history[gameState.history.length - 1][prevPlayer] as Drawing).data}/>
    <PlayerDirectionIndicator prev={prevPlayer} next={nextPlayer}/>
    <h1 className={'fixed top-0 left-0 right-0 text-center text-2xl font-bold mt-6'}>
      What the f*ck is this?
    </h1>
    <div className={'fixed bottom-0 left-0 right-0 gap-x-2 grid grid-flow-col auto-cols-max place-content-center mb-16'}>
      <span className={'text-lg'}>This is:</span>
      <PTextField disabled={submitted} value={guess} onChange={setGuess} onEnter={onSubmit}/>
      <PButton disabled={submitted} className={'mx-6'} onClick={onSubmit}>
        Submit
      </PButton>
    </div>
    <div className={'fixed top-0 left-0 pt-8 pl-8 flex items-center'}>
      <div className={'w-36'}>
        <p>
          Count down:{' '}
          <CountDown until={gameState.nextStarts}/>
        </p>
        <FinishCounter word={'submitted'} curr={Object.keys(gameState.guessings).length - 1} total={Object.keys(gameState.roomInfo.players).length} onFinish={onFinish}/>
      </div>
      <PlayerListButton gameState={gameState} roomRef={roomRef} user={user}/>
    </div>
  </>
}