import {UserName} from './UserName'
import React from 'react'

export default function PlayerDirectionIndicator(props:{prev:string,next:string}){
  return <div className={'fixed bottom-0 left-0 right-0 grid gap-x-16 grid-flow-col auto-cols-max place-content-center mb-2 text-xl'}>
    <UserName uid={props.prev}/>
    <span>{'->'}</span>
    <span>Me</span>
    <span>{'->'}</span>
    <UserName uid={props.next}/>
  </div>
}