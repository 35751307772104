import React from 'react'
import {RoughNotation} from 'react-rough-notation'

export default function PDialog(props: { children: React.ReactNode, className?: string, open: boolean }) {
  return props.open ? <div
    className={'fixed left-0 right-0 top-0 bottom-0 z-40 bg-black bg-opacity-40 flex items-center justify-center'}
    onClick={e => e.stopPropagation()}>
    <RoughNotation type={'box'} show animate={false} padding={0} strokeWidth={2}>
      <div className={`flex flex-col items-center bg-white bg-no-repeat bg-cover bg-fixed ${props.className}`} style={{backgroundImage:'url("/bg.jpg")'}}>
        {props.children}
      </div>
    </RoughNotation>
  </div> : null
}
