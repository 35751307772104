import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import HomePage from './homePage/HomePage'
import GamePage from './gamePage/GamePage'

const app = initializeApp({
  apiKey: 'AIzaSyBnPQxEyN7a6xbPzb1WZLSnudIHd5fAYXo',
  authDomain: 'draw-and-guess-pegasis.firebaseapp.com',
  projectId: 'draw-and-guess-pegasis',
  storageBucket: 'draw-and-guess-pegasis.appspot.com',
  messagingSenderId: '829335658309',
  appId: '1:829335658309:web:d71d3d9b97e010b707e82c',
  measurementId: 'G-B49L24S59B',
})
getAnalytics(app)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<HomePage/>}/>
        <Route path={'/room/:roomID'} element={<GamePage/>}/>
        <Route path="*" element={<Navigate replace to={'/'}/>}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)