import React, {forwardRef} from 'react'
import {useWindowSize} from 'react-use'
// @ts-ignore
import CanvasDraw from '@pegasis/react-canvas-draw'

const Canvas = forwardRef<CanvasDraw, { strokeColor?: string, strokeWidth?: number, disabled?: boolean, submitted?: boolean, saveData?: string, animation?: boolean }>(function Canvas(props: { strokeColor?: string, strokeWidth?: number, disabled?: boolean, submitted?: boolean, saveData?: string, animation?: boolean }, ref) {
  const windowSize = useWindowSize()

  let w = windowSize.width - 100
  let h = windowSize.height - 250
  if (h / 9 * 16 > w) {
    h = w / 16 * 9
  } else if (w / 16 * 9 > h) {
    w = h / 9 * 16
  }

  return <>
    <div className={'fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center'}>
      <CanvasDraw
        ref={ref}
        className={'shadow-lg'}
        canvasWidth={w}
        canvasHeight={h}
        lazyRadius={2}
        brushRadius={(props.strokeWidth ?? 0) / 2}
        hideInterface
        immediateLoading={props.animation !== true}
        saveData={props.saveData}
        disabled={props.disabled || props.submitted}
        animationDuration={1000}
        brushColor={props.strokeColor}/>
    </div>
    {props.submitted ? <div className={'fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center'}>
      <div className={'bg-black bg-opacity-50 flex flex-col items-center justify-center text-white'} style={{width: w + 'px', height: h + 'px'}}>
        <h2 className={'text-2xl'}>Submitted</h2>
        <p className={'mt-6 text-lg'}>Waiting for other players.....</p>
      </div>
    </div> : null}
  </>
})

export default Canvas