import {useNavigate, useParams} from 'react-router-dom'
import React, {MutableRefObject, useEffect, useMemo, useState} from 'react'
import {GameState} from '../model/GameState'
import {get, getDatabase, onValue, ref, set, Unsubscribe} from 'firebase/database'
import produce from 'immer'
import WaitingFragment from './fragments/WaitingFragment'
import {useAuthedUser} from '../util/auth'
import SelectingWordsFragment from './fragments/SelectingWordsFragment'
import DrawingFragment from './fragments/DrawingFragment'
import GuessingFragment from './fragments/GuessingFragment'
import PresentationFragment from './fragments/PresentationFragment'

export default function GamePage() {
  let {roomID} = useParams()
  let navigate = useNavigate()
  let [gameState, setGameState] = useState<GameState | null>(null)
  const user = useAuthedUser()
  const roomRef = useMemo(() => {
    if (user == null) return null
    const db = getDatabase()
    return ref(db, `rooms/${roomID}`)
  }, [roomID, user])

  useEffect(() => {
    if (!user || !roomRef) return

    const unsubscribeRef: MutableRefObject<Unsubscribe | null> = {current: null};
    (async () => {
      const roomSnapshot = await get(roomRef)
      if (!roomSnapshot.exists()) {
        navigate('/', {replace: true})
        return
      }

      let gameState = roomSnapshot.val() as GameState
      if (gameState.state !== 'ghost' && gameState.roomInfo.kickedPlayers[user.uid]) {
        // kicked
        alert('You have been kicked from this room.')
        navigate('/', {replace: true})
        return
      }
      if (gameState.state !== 'ghost' && gameState.roomInfo.players[user.uid]) {
        // already joined before
      } else if (gameState.state === 'waiting') {
        // new user
        gameState = produce(gameState, draft => {
          draft.roomInfo.players[user.uid] = 1
        })
        set(roomRef, gameState)
      } else {
        // new user, game already started, or ghost state
        // todo error can't join
        navigate('/', {replace: true})
        return
      }

      setGameState(gameState)
      unsubscribeRef.current = onValue(roomRef, snapshot => {
        setGameState(snapshot.val())
      })
    })()

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current()
      }
    }
  }, [user, roomID, navigate, roomRef])

  useEffect(() => {
    if (user && gameState && gameState.state !== 'ghost' && gameState.roomInfo.kickedPlayers[user.uid]) {
      alert('You have been kicked from this room.')
      navigate('/', {replace: true})
    }
  }, [gameState, navigate, user])

  if (gameState === null || !user || !roomRef) {
    return <div className={'fixed left-0 right-0 top-0 bottom-0 flex items-center justify-center'}>
      <p className={'text-2xl'}>Loading.....</p>
    </div>
  }

  if (gameState.state === 'waiting') return <WaitingFragment gameState={gameState} user={user} roomRef={roomRef}/>
  if (gameState.state === 'selecting') return <SelectingWordsFragment gameState={gameState} user={user} roomRef={roomRef}/>
  if (gameState.state === 'drawing') return <DrawingFragment gameState={gameState} user={user} roomRef={roomRef}/>
  if (gameState.state === 'guessing') return <GuessingFragment gameState={gameState} user={user} roomRef={roomRef}/>
  if (gameState.state === 'presentation') return <PresentationFragment gameState={gameState} user={user} roomRef={roomRef}/>

  return null
}