import {RoughNotation} from 'react-rough-notation'
import React from 'react'

export default function PTextField(props: { value: string, disabled?: boolean, className?: string, onChange: (text: string) => void, onEnter?: () => void }) {
  return <RoughNotation type={'box'} show animate={false} padding={0} strokeWidth={2}>
    <input
      onKeyDown={e => {
        if (e.key === 'Enter' && props.onEnter) {
          props.onEnter()
        }
      }}
      disabled={props.disabled}
      value={props.value}
      onChange={e => props.onChange(e.target.value)}
      className={`w-64 bg-black bg-opacity-10 border-none active:border-none focus:ring-transparent focus:bg-opacity-20 text-lg px-2 py-1 ${props.className}`} type="text"/>
  </RoughNotation>
}
